import { createSlice } from "@reduxjs/toolkit";

const excelData = [];

const chartFilterSlice = createSlice({
    name: "chartFilter",
    initialState: {
        filteredData: excelData,
        LORFilter: 0
    },
    reducers: {
        resetFilter(state) {
            state.filteredData = [];
            state.LORFilter = 0
        },
        setFilter(state, action) {
            state.filteredData = action.payload
        },
        setLORFilter(state, action) {
            state.LORFilter = action.payload
        }
    }
})

export const { resetFilter, setFilter, setLORFilter } = chartFilterSlice?.actions;

export default chartFilterSlice?.reducer