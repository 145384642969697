import { useCallback, useMemo, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import { logout } from '../helpers/logOut';
import { useNavigate } from 'react-router-dom';

const useApi = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const { accessToken, userDetails } = useSelector(state => (state?.authDatas));

    const authheaders = useMemo( ()=> ({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        'Access-Control-Allow-Origin': '*'
    }), [accessToken])
    const headers = useMemo(() => ({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }), [])
    const checkTokenValidity = useCallback(() => {
        const expireTime = moment(userDetails?.time)?.valueOf() + 86400000;
        const currentTime = moment()?.valueOf();

        if (accessToken && userDetails?.time) {
            if (currentTime > expireTime) {
                logout(navigate, dispatch)
            } else {
                return false
            }
        } else {
            return false
        }
    },[accessToken, dispatch, navigate, userDetails?.time])
    const fetchData = useCallback(async (endpoint, method = 'POST', payload, setLoading = () => { }) => {
        setLoading(true);
        setError(null); // Clear previous errors when making a new request
        const checkTokenExpiry = checkTokenValidity();
        if (!checkTokenExpiry) {

            try {
                const config = {
                    method,
                    url: `${process.env.REACT_APP_API_URL}${endpoint}`,
                    headers: accessToken ? authheaders : headers,
                    data: payload,
                };

                const response = await axios(config);
                setLoading(false);
                return response?.data;
            } catch (err) {
                setLoading(false);
                if (axios.isCancel(err)) {
                    console.log('Request canceled:', err.message);
                }
                // Extract and store only the relevant error message
                setError(err.response?.data?.message || 'An error occurred');
            } finally {
                setLoading(false);
            }
        }
    }, [accessToken, authheaders, checkTokenValidity, headers]);

    return { error, fetchData };
};

export default useApi;
