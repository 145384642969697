import { createSlice } from "@reduxjs/toolkit";

const initialState = { competitorList: [] };

const competitorListSlice = createSlice({
    name: "competitorList",
    initialState,
    reducers: {
        resetCompetitorList(state) {
            state.competitorList = {}
        },
        setCompetitorList: (state, action) => {
            state.competitorList = action.payload
        }
    }
});

export const { resetCompetitorList, setCompetitorList } = competitorListSlice?.actions;

export default competitorListSlice?.reducer