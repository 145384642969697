import React, { useState, memo } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Box, Toolbar, IconButton, Typography, MenuItem, Button, Avatar, Popover } from "@mui/material";
import BreadCrumbs from '../bread-crumbs/BreadCrumbs';
import LazyImage from '../images/Images';
import { logout } from '../../utils/helpers/logOut';
import { fullPageView } from '../../utils/helpers/fullPageView';
import { setIsSidemenuOpen } from '../../services/slices/sideMenuSlice';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import avatar from "../../assets/images/avatar.png"
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import PersonIcon from '@mui/icons-material/Person';
import logo from "../../assets/images/logo.webp";
import "./Header.scss";

const Header = ({ tableRef }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const logoutOpen = Boolean(anchorEl);
  const id = logoutOpen ? 'simple-popover' : undefined;
  const [currentPage, setCurrentPage] = useState("");
  const { isSidemenuOpen } = useSelector(state => (state?.isSidemenuOpen));
  const { userDetails } = useSelector(state => (state?.authDatas));

  const handleClick = (event, type) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let currentUrl = window.location.href.split("/");
  let current_page = currentUrl[currentUrl?.length - 1];
  if (current_page !== currentPage) {
    setCurrentPage(current_page);
  }

  const handleDrawerClose = () => {
    dispatch(setIsSidemenuOpen(!isSidemenuOpen));
  };

  const gotoMyAccount = () => {
    navigate(`/my-account`);
    setAnchorEl(null);
  }

  return (
    <div className="header-style" ref={tableRef}>
      <AppBar className="headermenu">
        <Toolbar disableGutters className="header-container d-flex justyfy-space-between">
          <Box className="d-flex align-center">
            <IconButton onClick={() => handleDrawerClose()}>
              <MenuIcon />
            </IconButton>
            <div className="desk-breadcrumbs">
              <BreadCrumbs />
            </div>
            <div className="mob-logo">
              <LazyImage src={logo} alt={"Rental_BI"} />
            </div>
          </Box>
          {/* Desktop and ipad view menu */}
          <Box
            sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}
            className="menusection" role="menu"
          >
            <ul role="presentation" className='d-flex m-0'>
              <MenuItem>
                <IconButton onClick={() => fullPageView(tableRef)}>
                  <FullscreenIcon />
                </IconButton>
              </MenuItem>
              <MenuItem role="menuitem" id="logged-user">
                <IconButton sx={{ p: 0 }} onClick={(e) => handleClick(e, "avatar")} aria-describedby={id}>
                  <Avatar alt="Remy Sharp" src={avatar} sx={{ width: 24, height: 24 }} />
                  {userDetails?.firstname ? <Typography className="capitalize">{userDetails?.firstname}</Typography> :
                    <Typography className="capitalize">Demo Aggregate</Typography>}
                  <ExpandMoreRoundedIcon />
                </IconButton>
              </MenuItem>
            </ul>

          </Box>
          {/* Mobile view Menu start */}
          <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }} className="mobile-menu">
            <>
              <MenuItem>
                <IconButton sx={{ p: 0 }} onClick={(e) => handleClick(e, "avatar")} aria-describedby={id}>
                  <Avatar alt="Remy Sharp" src={avatar} sx={{ width: 24, height: 24 }} />
                </IconButton>
              </MenuItem>
            </>

            <Popover
              id={id}
              open={logoutOpen}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              className="logout-dropdown"
            >
              <Button onClick={() => gotoMyAccount()}><PersonIcon />My Account</Button>
              <Button onClick={() => { logout(navigate, dispatch) }}><LogoutIcon />Logout</Button>
            </Popover>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default memo(Header)
