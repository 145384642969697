import React, { Suspense, memo, useEffect, useCallback, useMemo, useRef, useState } from "react";
import { Outlet, useNavigate, useLocation, useParams } from "react-router-dom";
// import { useIdleTimer } from 'react-idle-timer'; // Import the useIdleTimer hook
import SideMenu from "../../components/sidemenu/SideMenu";
import Header from "../../components/header/Header";
import Loader from "../../components/loader/Loader";
import BreadCrumbs from "../../components/bread-crumbs/BreadCrumbs";
import "./DashboardLayout.scss";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import ExpiredModal from "../../components/expiredModal/ExpiredModal";
// import { Button } from "@mui/material";
import "../../components/expiredModal/ExpiredModal.scss";
// import { logout } from "../../utils/helpers/logOut";
import { jwtDecode } from "jwt-decode";
import { setAuthData } from "../../services/slices/authSlice";

const DashboardLayout = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const tableRef = useRef();
    const params = useParams();
    const urlParams = useMemo(() => (new URLSearchParams(location.search)), [location.search]);
    const jwt = urlParams.get('token');

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const { accessToken, userDetails } = useSelector(state => (state?.authDatas));
    // const [counter, setCounter] = useState(0);
    // const CurrentLogCondition = useRef(false);
    // const [modalIsOpen, setModalIsOpen] = useState(false);

    // useEffect(() => {
    //     if (counter !== 0) {
    //       const intervalId = setInterval(() => {
    //         setCounter((prevSeconds) => prevSeconds - 1);
    //       }, 1000);

    //       return () => clearInterval(intervalId);
    //     } else if (modalIsOpen) {
    //     //   setTimeout(setIsLoggedIn(true), 10000)
    //     }
    //   }, [counter, modalIsOpen]);

    const spoofLogin = useCallback((spoofToken) => {
        const decoded = jwtDecode(spoofToken);
        if ((decoded?.username === userDetails?.username) || !userDetails?.username) {
            dispatch(setAuthData({
                accessToken: spoofToken, userId: decoded?.userid, userDetails: {
                    userid: decoded?.userid,
                    username: decoded?.username,
                    email: decoded?.email,
                    firstname: decoded?.firstname,
                    lastname: decoded?.lastname,
                    secondaryemails: [...decoded?.secondaryemails],
                    createddate: decoded?.createddate,
                    time: decoded?.time
                }
            }));
        }
        if (decoded?.isSpoofToken !== true) {
            alert("Invalid Spoofing Attempt!")
        }

        if (userDetails?.username && userDetails?.username !== decoded?.username) {
            alert("To spoof login, please logout of the client account, close the browser tab and retry")
            window.location.href = "/markets";
            return
        }
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!jwt && !location?.pathname.includes("?token=") && !!accessToken && !params.marketId && !params.categoryId) {
            navigate("/markets")
        }
        setTimeout(() => {
            if (jwt) {
                spoofLogin(jwt);
                navigate(`/markets?token=${jwt}`);
            }
        }, 100); // Adjust the delay as needed
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        const checkTokenValidity = () => {
            if (!isLoggedIn) {
                const expireTime = moment(userDetails?.time)?.valueOf() + 86400000;
                const currentTime = moment()?.valueOf();
                if (accessToken && userDetails?.time) {
                    if (currentTime < expireTime) {
                        setIsLoggedIn(false);
                    } else {
                        setIsLoggedIn(true);
                        localStorage.removeItem('token');
                        localStorage.removeItem('tokenIssueTime');
                        // You might want to redirect the user to a login page or show a message indicating that the session has expired.
                    }
                } else {
                    setIsLoggedIn(false);
                }
            }
        };

        checkTokenValidity(); // Check token validity when component mounts

        const interval = setInterval(checkTokenValidity, 1000); // Check token validity every minute

        return () => clearInterval(interval); // Clear the interval on component unmount

    }, [accessToken, isLoggedIn, userDetails?.time])


    // useIdleTimer({
    //     timeout: 90000,
    //     onIdle: async () => {
    //         setModalIsOpen(true);
    //         setCounter(60)
    //         if (!CurrentLogCondition.current && modalIsOpen) {
    //             // setTimeout(setIsLoggedIn(true), 10000)
    //         }
    //     },
    //     debounce: 500,
    // });

    // const stayLoggedIn = () => {
    //     setModalIsOpen(false);
    //     CurrentLogCondition.current = true;
    //     window.location.reload();
    // };


    return (
        <>
            {!!isLoggedIn && <ExpiredModal setIsLoggedIn={setIsLoggedIn} />}
            {/* {(!!modalIsOpen && !isLoggedIn )&& <div className='section-expiry'>
                <div className='section-box'>
                    <h1>Session Expire Warning</h1>
                    <p>Your session has expired. Would you like to extend the session?</p>
                    <div className='text-right' ><Button onClick={() => { stayLoggedIn() }}>Yes</Button> <Button onClick={() => { logout(navigate, dispatch) }}>NoN</Button> </div>
                </div>
            </div>} */}
            <div className="mainlayout-container" ref={tableRef}>
                <SideMenu />
                <div className="overflow-scroll w-100">
                    <Header tableRef={tableRef} />
                    <div className=" header-style mob-breadcrumbs boxed-shadow mt-2">
                        <BreadCrumbs />
                    </div>
                    <div className="main-data-area">
                        <Suspense fallback={!isLoggedIn && <Loader />}>
                            <Outlet />
                        </Suspense>
                    </div>
                </div>
            </div>
        </>
    )
}

export default memo(DashboardLayout)
