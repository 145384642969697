import React, { memo, useEffect, useState } from 'react';
import { Box, Button, Collapse, CssBaseline, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { Drawer as DesktopDrawer } from '../../utils/helpers/drawer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setIsSidemenuOpen } from '../../services/slices/sideMenuSlice';
import { setLORFilter } from '../../services/slices/chartFilter';
import { resetCustomCategory, setCategoryIsLoading } from '../../services/slices/customCategorySlice';
import { resetCategory } from '../../services/slices/categorySlice';
import { reSetIsLOR } from "../../services/slices/lorSlice";
import { resetIsDaysRange } from "../../services/slices/daysRangeSlice";
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import ReplyRoundedIcon from '@mui/icons-material/ReplyRounded';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import LazyImage from '../images/Images';
import logo from "../../assets/images/logo.webp";
import logoSign from "../../assets/images/logo-sign.png";
import "./Sidemenu.scss";

const SideMenu = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data } = useSelector(state => (state?.allMarketsData));
  const { isSidemenuOpen } = useSelector(state => (state?.isSidemenuOpen))
  const { customCategory } = useSelector(state => (state?.customCategories));
  const { breadCrumbStack } = useSelector(state => state?.breadCrumbs);
  const { isDaysRange } = useSelector(state => state?.isDaysRange);
  const [open, setOpen] = useState(isSidemenuOpen);
  const [identifyOpen, setIdentifyOpen] = useState(false);
  const [locationListOpen, setLocationListOpen] = useState(true);
  const [searchValue, setSearchValue] = useState({ marketSearch: "", categortSearch: "" })
  const [searchedData, setSearchedData] = useState({ marketData: data?.data?.allMarkets, categortData: customCategory });
  const singleMarketData = data?.data?.allMarkets?.filter((res) => parseInt(res?.marketId) === parseInt(params?.marketId));

  useEffect(() => {
    setSearchedData({ marketData: data?.data?.allMarkets, categortData: customCategory })
  }, [customCategory, data?.data?.allMarkets]);

  useEffect(() => {
    setOpen(isSidemenuOpen);
  }, [isSidemenuOpen]);

  useEffect(() => {
    if (location.state === null) {
      setLocationListOpen(true)
    } else if ((location?.state?.from === "allMarkets" || location?.state?.from === "allMarketsTable" || location?.state?.from === "activityReports" || location?.state?.from === "marketalerts") && identifyOpen === false) {
      setLocationListOpen(false)
      setIdentifyOpen(false)
    } else if (params?.marketId && (location?.state?.from === "allMarkets" || location?.state?.from === "allMarketsTable") && identifyOpen === true) {
      setLocationListOpen(true)
    } else if (location?.state?.from === "backToAllMarkets" || location?.state?.from === "categoriesPage") {
      setLocationListOpen(true)
    } else if (location?.state?.from === "allCategories" || location?.state?.from === "activityReports") {
      setLocationListOpen(false)
    }
  }, [location.state, identifyOpen, params?.marketId])

  const activeMenuList = breadCrumbStack?.map(item => item?.labelid);
  const activeSubMenuList = breadCrumbStack?.map(item => item?.customlabelid);

  const navigateToCategory = (val) => {
    if (val !== "") {
      dispatch(resetCustomCategory());
      dispatch(resetCategory());
      dispatch(setCategoryIsLoading(true));
      setSearchValue({ categortSearch: "", marketSearch: "" })
      const stateData = { from: "allMarkets", marketName: `${val?.marketName}`, marketId: val?.marketId, currencyCode: val?.currencyCode, countryCode: val?.countryCode, baseCompetitorId: val?.baseCompetitorId };
      navigate(`/market-dashboard/${val?.marketId}`, { state: stateData })

    }
    if (location?.state === null) {
      setIdentifyOpen(false)
    } else if (location?.state?.from === "allMarkets") {
      setIdentifyOpen(true)
    }
  }

  const navigatetoCharts = (ctg) => {
    dispatch(setLORFilter(1))
    setSearchValue({ categortSearch: "", marketSearch: "" })
    setSearchedData({ marketData: data?.data?.allMarkets, categortData: customCategory })
    const marketNames = data?.data?.allMarkets?.filter(fD => parseInt(fD.marketId) === parseInt(params?.marketId))
    navigate(`view-details/${ctg?.customcategoryid}`, { state: { marketDetails: { marketId: params?.marketId, from: "category", marketName: marketNames[0]?.marketName }, category: ctg, categoryType: ctg?.customcategorylabel, categoryId: ctg?.customcategoryid, daysHorison: isDaysRange } })
  }

  const handleMarketList = () => {
    if (open) {
      setLocationListOpen(!locationListOpen);
      setIdentifyOpen(true)
    } else {
      setLocationListOpen(true);
      setIdentifyOpen(true)
      dispatch(setIsSidemenuOpen(!isSidemenuOpen));
    }
  }

  const onSearchChange = (e, from) => {
    if (from === "Markets") {
      setSearchValue({ categortSearch: "", marketSearch: e.target.value })
      const categoryData = (customCategory || []).filter(itm => itm?.marketId === location?.state?.marketDetails?.marketId);
const marketData = (data?.data?.allMarkets || []).filter(marketData => 
    (marketData?.marketName?.toLocaleLowerCase().includes(e.target.value?.toLocaleLowerCase()) || 
    marketData?.marketName?.replace(/\s/g, '').toLocaleLowerCase().includes(e.target.value?.toLocaleLowerCase()))
);
setSearchedData({ 
  categoryData, 
  marketData 
});
      // setSearchedData({ categortData: [...customCategory]?.filter(itm => itm?.marketId === location?.state?.marketDetails?.marketId), 
      //   marketData: data?.data?.allMarkets?.filter(marketData => ((marketData?.marketName?.toLocaleLowerCase())?.includes(e.target.value?.toLocaleLowerCase()) || 
      //   (marketData?.marketName?.replace(/\s/g, '')?.toLocaleLowerCase())?.includes(e.target.value?.toLocaleLowerCase()))) })
    } else if (from === "Categories") {
      setSearchValue({ categortSearch: e.target.value, marketSearch: "" })
      setSearchedData({ marketData: data?.data?.allMarkets, categortData: [...customCategory]?.filter(itm => (itm?.customcategorylabel?.toLocaleLowerCase()?.includes(e.target.value?.toLocaleLowerCase()) || itm?.customcategorylabel?.replace(/\s/g, '')?.toLocaleLowerCase()?.includes(e.target.value?.toLocaleLowerCase()))) })
    }
  };

  const handleOnClose = () => {
    dispatch(setIsSidemenuOpen(!isSidemenuOpen));
  }

  const menuList = (open) => (
    <>
      <Box className={open ? "logo-section" : "logo-sign"}>
        {open ? <LazyImage src={logo} alt={"Rental_BI"} /> : <LazyImage src={logoSign} alt={"Rental_BI_Sign"} />}
      </Box>
      <Divider />

      <List className="menu-list">
        {!!params?.marketId ?
          <>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton className={""} onClick={() => {
                dispatch(reSetIsLOR());
                dispatch(resetIsDaysRange());
                navigate("/markets");
              }}>
                <ListItemIcon>
                  <ReplyRoundedIcon />
                </ListItemIcon>
                <ListItemText primary={"Back to All Markets"} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </>
          : ""}
        <Divider />

        <ListItem disablePadding sx={{ display: 'block' }}>
          <ListItemButton className={locationListOpen === true && (!params.marketId || params?.categoryId) ? "active-menu" : ""} onClick={(e) => handleMarketList()} >
            <ListItemIcon>
              {!params?.categoryId ? <BarChartIcon /> : <ReceiptLongIcon />}
            </ListItemIcon>
            <ListItemText primary={`${!params?.categoryId ? "All Markets" : "Categories"}`} sx={{ opacity: open ? 1 : 0 }} />
            {open && <>{locationListOpen ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}</>}
          </ListItemButton>
          {open &&
            <Collapse in={locationListOpen} timeout="auto" unmountOnExit>
               <input type='text' className="common-searchbox" placeholder={!params?.categoryId ? "Search by market" : "Search by category"} onChange={(e) => { onSearchChange(e, !params?.categoryId ? "Markets" : "Categories") }} value={!params?.categoryId ? searchValue?.marketSearch : searchValue?.categortSearch} />
              {!params?.categoryId ? <List component="div" disablePadding className='market-list'>
                {searchedData?.marketData?.length ? [...searchedData?.marketData]?.sort((a, b) => a?.marketName.localeCompare(b?.marketName))?.map((data, idx) => {
                  return <Button key={idx} onClick={() => navigateToCategory(data)} className={activeMenuList?.includes(parseInt(data?.marketId)) ? "active-menulist" : ""}>
                    {data?.marketName}
                  </Button>
                }) : searchedData?.marketData?.length === 0 ? <span>No Market Found</span> : <></>}
              </List> : <List component="div" disablePadding className='market-list'>
                {searchedData?.categortData?.length ? [...searchedData?.categortData]?.sort((a, b) => a?.customcategorylabel.localeCompare(b?.customcategorylabel))?.map((data, idx) => {
                  return <Button key={idx} onClick={() => navigatetoCharts(data)} disabled={data?.rates === undefined ? true : false} className={activeSubMenuList?.includes(parseInt(data?.customcategoryid)) ? "active-menulist" : data?.rates === undefined ? "ifdisables" : ""}>
                    {data?.customcategorylabel}
                  </Button>
                }) : <span>No Categories Found</span>}
              </List>}
            </Collapse>
          }
        </ListItem>
        {location?.pathname?.includes("market-dashboard") || location?.pathname?.includes("activity-report") ?
          <>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={() => navigate(`/market-dashboard/${params?.marketId}`, {
                  state: {
                    from: "allCategories", marketName: `${singleMarketData[0]?.marketName}`, marketId: singleMarketData[0]?.marketId,
                    currencyCode: singleMarketData[0]?.currencyCode, countryCode: singleMarketData[0]?.countryCode, baseCompetitorId: singleMarketData[0]?.baseCompetitorId
                  }
                })}
                className={(locationListOpen === true || locationListOpen === false) && (location?.state?.from === "allCategories" || location?.state?.from === "allMarkets" || location?.state?.from === "allMarketsTable") ? "active-menu" : ""} >
                <ListItemIcon>
                  <LayersIcon />
                </ListItemIcon>
                <ListItemText primary={"All Categories"} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={() => navigate(`market-alerts`, { state: { marketId: params.marketId, from: "marketalerts" } })}
                className={(locationListOpen === true || locationListOpen === false) && location?.state?.from === "marketalerts" ? "active-menu" : ""} >
                <ListItemIcon>
                  <NewReleasesIcon />
                </ListItemIcon>
                <ListItemText primary={"Market Alerts"} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={() => navigate(`activity-report`, { state: { marketId: params.marketId, from: "activityReports" } })}
                className={(locationListOpen === true || locationListOpen === false) && location?.state?.from === "activityReports" ? "active-menu" : ""} >
                <ListItemIcon>
                  <LocalActivityIcon />
                </ListItemIcon>
                <ListItemText primary={"Activity Reports"} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </> :
          ""}
      </List>
    </>
  )

  return (
    <Box className="sidemenu-container">
      <CssBaseline />
      <Drawer anchor={"left"} open={!open} onClose={() => handleOnClose()} className="left-sidemenu mob-drawer" sx={{ width: 200 }}>
        {menuList(!open)}
      </Drawer>
      <DesktopDrawer variant="permanent" open={open} className="left-sidemenu desk-drawer">
        {menuList(open)}
      </DesktopDrawer>
    </Box>
  )
}

export default memo(SideMenu)
