import { lazy } from "react";
import { lazyRetry } from "../../utils/hooks/lazyRetry";

const LazyLogin = lazy(() =>
  lazyRetry(() => import("../../pages/dynamic/login/Login"))
);
const LazyAllMarkets = lazy(() =>
  lazyRetry(() => import("../../pages/dynamic/all-markets/AllMarkets"))
);
const LazyMyAccount = lazy(() =>
  lazyRetry(() => import("../../pages/dynamic/my-account/MyAccount"))
);
const LazyNotFound = lazy(() =>
  lazyRetry(() => import("../../pages/static/notFound/NotFound"))
);
const LazyCharts = lazy(() =>
  lazyRetry(() => import("../../pages/dynamic/charts/Charts"))
);
const LazyMarketDashboard = lazy(() =>
  lazyRetry(() => import("../../pages/dynamic/categories/MarketDashboard"))
);
const LazyMarketAlerts = lazy(() => 
  lazyRetry(() => import("../../pages/dynamic/market-alerts/MarketAlerts"))
);
const LazyActivityReport = lazy(() => 
  lazyRetry(() => import("../../pages/dynamic/activity-report/ActivityReport"))
);
const LazyCategorySetup = lazy(() => 
  lazyRetry(() => import("../../components/category-setup/CategorySetup"))
);

const LazyComponents = () => {

  return ({
    LazyLogin,
    LazyCharts,
    LazyNotFound,
    LazyMyAccount,
    LazyAllMarkets,
    LazyMarketDashboard,
    LazyMarketAlerts,
    LazyActivityReport,
    LazyCategorySetup
  });
}

export default LazyComponents;