import { createSlice } from "@reduxjs/toolkit";

const accessToken = "";
const userId = null;
const userDetails = {};

const authSlice = createSlice({
    name: "auth",
    initialState: {
        accessToken,
        userId,
        userDetails,
    },
    reducers: {
        reSetAuthData(state) {
            state.accessToken = accessToken;
            state.userDetails = userDetails;
            state.userId = userId;
        },
        setAuthData(state, action) {
            state.accessToken = action?.payload?.accessToken
            state.userId = action?.payload?.userId
            state.userDetails= action?.payload?.userDetails
        }
    }
});

export const { setAuthData, reSetAuthData } = authSlice?.actions;

export default authSlice?.reducer;
