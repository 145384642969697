// import storage from "redux-persist/lib/storage";
import persistStore from "redux-persist/es/persistStore";
import persistReducer from "redux-persist/es/persistReducer";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authslice from "../services/slices/authSlice";
import expandSlice from "../services/slices/expandSlice";
import chartDataSlice from "../services/slices/allChartData";
import categorySlice from "../services/slices/categorySlice";
import customCategorySlice from "../services/slices/customCategorySlice";
import allCustomCategory from "../services/slices/allCustomCategory";
import sideMenuSlice from "../services/slices/sideMenuSlice";
import allMarketsData from "../services/slices/allMarketsData";
import chartFilteredslice from "../services/slices/chartFilter";
import breadcrumbsSlice from "../services/slices/breadcrumbsSlice";
import alertSlice from "../services/slices/alertSlice";
import alertLogs from "../services/slices/alertLogs";
import lorSlice from "../services/slices/lorSlice";
import daysRangeSlice from "../services/slices/daysRangeSlice";
import activityReport from "../services/slices/activityReport";
import marketdata from "../services/slices/marketdata";
import localforage from "localforage";
import competitorList from "../services/slices/competitorList";
import isLastRefreshDateSlice from "../services/slices/lastRefreshDate";

const PersistConfig = {
    key: "root",
    storage: localforage,
};

const rootReducer = combineReducers({
    authDatas: authslice,
    isExpand: expandSlice,
    alertSlice: alertSlice,
    alertLogs: alertLogs,
    categories: categorySlice,
    customCategories: customCategorySlice,
    allCustomCategories: allCustomCategory,
    chartData: chartDataSlice,
    isSidemenuOpen: sideMenuSlice,
    breadCrumbs: breadcrumbsSlice,
    allMarketsData: allMarketsData,
    chartFilteredData: chartFilteredslice,
    isLOR: lorSlice,
    isDaysRange: daysRangeSlice,
    activityReport: activityReport,
    marketdata: marketdata,
    competitorList: competitorList,
    lastRefreshDate: isLastRefreshDateSlice
})

const persistedReducer = persistReducer(PersistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),

    devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);
