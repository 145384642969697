import { Button } from '@mui/material'
import React, { useState } from 'react';
import "./ExpiredModal.scss";
import { logout } from '../../utils/helpers/logOut';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ApiServices from '../../services/api-services/apiServices';


const ExpiredModal = ({setIsLoggedIn}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { loginTokenUpdate } = ApiServices();
    const [isLoginTokenLoading, setIsLoginTokenLoading] = useState(false);

    // logout(navigate, dispatch);

    const handleTokenUpdata = async() => {
        const payload = {}
        const loginResponse = await loginTokenUpdate(payload, setIsLoginTokenLoading);
        if(loginResponse?.userDetails?.userid){
            setIsLoggedIn(false);
            window.location.reload();
        }
    }

    return (
        <div className='section-expiry'>
            <div className='section-box'>
                <h1>Session Expire Warning</h1>
                <p>Your session has expired. Would you like to extend the session? </p>
                <div className='text-right' ><Button onClick={() => { handleTokenUpdata() }}>Yes</Button> <Button onClick={() => { setIsLoggedIn(false); logout(navigate, dispatch) }}>No</Button></div>
            </div>
        </div>
    )
}

export default ExpiredModal