import { createSlice } from "@reduxjs/toolkit";

const data = {};
const marketIds = []

const allMarketsDataSlice = createSlice({
    name: "allMarketsData",
    initialState: {
        data,
        marketIds
    },
    reducers: {
        resetMarketsData(state) {
            state.data = {};
            state.marketIds = []
        },
        setMarketsData(state, action) {
            state.data = action?.payload?.data
            state.marketIds = action?.payload?.marketIds
        }
    }
});

export const { resetMarketsData, setMarketsData } = allMarketsDataSlice?.actions;

export default allMarketsDataSlice?.reducer;
