import { createSlice } from "@reduxjs/toolkit";

const breadCrumbStack = [
    { stackId: 1, path: "/markets", label: "Home" }
]

const breadCrumbsSlice = createSlice({
    name: "auth",
    initialState: {
        breadCrumbStack
    },
    reducers: {
        reSetBreadCrumbStack(state) {
            state.breadCrumbStack = breadCrumbStack
        },
        setBreadCrumbStack(state, action) {
            state.breadCrumbStack = action.payload
        },
    }
});

export const { setBreadCrumbStack, reSetBreadCrumbStack } = breadCrumbsSlice?.actions;

export default breadCrumbsSlice?.reducer;
