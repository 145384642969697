import { createSlice } from "@reduxjs/toolkit";

const isLOR =1;

const isLORSlice = createSlice({
    name: "issidemenuopen",
    initialState: {
        isLOR,
    },
    reducers: {
        reSetIsLOR(state) {
            state.isLOR = isLOR
        },
        setIsLOR(state, action) {
            state.isLOR = action.payload;
        },
    },
});

export const { reSetIsLOR, setIsLOR } = isLORSlice.actions;

export default isLORSlice.reducer;