import { useCallback } from "react"
import useApi from "../../utils/hooks/fetchData";
import { useDispatch, useSelector } from "react-redux";
import { setMarketsData } from "../slices/allMarketsData";
import { setChartData } from "../slices/allChartData";
import { setCategory } from "../slices/categorySlice";
import { setSingleMarket } from "../slices/marketdata";
// import { setCustomCategory } from "../slices/customCategorySlice";
import { reSetActivityReport, setActivityReport, setActivityLOR } from "../slices/activityReport";
import { setAllCustomCategory } from "../slices/allCustomCategory";
import { setAlertsData } from "../slices/alertSlice";
import { setLogsData } from "../slices/alertLogs";
import axios from "axios";
import { setCompetitorList } from "../slices/competitorList";
import { setAuthData } from "../slices/authSlice";
import { setLastRefreshDate } from "../slices/lastRefreshDate";
import { setCategoryIsLoading, setCustomCategory } from "../slices/customCategorySlice";


const ApiServices = () => {
    const dispatch = useDispatch();
    const { fetchData } = useApi();
    const { accessToken } = useSelector(state => (state?.authDatas));

    const authConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        'responseType': 'arraybuffer',
    }

    const nonAuthConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        'responseType': 'arraybuffer',
    }

    const config = accessToken ? authConfig : nonAuthConfig;

    // Start Login Data 
    const loginData = useCallback(async (payload, setIsLoading) => {
        setIsLoading(true)
        try {
            const response = await fetchData("login", "POST", payload, setIsLoading);
            dispatch(setAuthData({ accessToken: response?.accessToken, userId: response?.userDetails?.userid, userDetails: response?.userDetails }));
            setIsLoading(false);
            return response;
        } catch (error) {
            console.error("Error fetching data:", error)
            // setIsLoading(false)
        }
    }, [dispatch, fetchData])
    // End Login Data

    // Start Login Data 
    const loginTokenUpdate = useCallback(async (payload, setIsLoading) => {
        setIsLoading(true)
        try {
            const response = await fetchData("token", "GET", setIsLoading);
            dispatch(setAuthData({ accessToken: response?.accessToken, userId: response?.userDetails?.userid, userDetails: response?.userDetails }));
            setIsLoading(false);
            return response;
        } catch (error) {
            console.error("Error fetching data:", error);
            // setIsLoading(false)
        }
    }, [dispatch, fetchData])
    // End Login Data

    //start market endpoints
    const fetchMarketsData = useCallback(async (payload, setIsLoading) => {
        setIsLoading(true)
        try {
            const response = await fetchData("allmarkets", "GET", setIsLoading);
            if (response === undefined) {
                const response = {
                    data: {
                        allMarkets: [],
                    }
                }
                dispatch(setMarketsData({ data: response, marketIds: [] }));
            } else {
                const marketIDs = response?.data?.allMarkets?.map((res) => res?.marketId)?.sort()
                dispatch(setMarketsData({ data: response, marketIds: marketIDs }))
            }
            setIsLoading(false)
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false)
        }
    }, [dispatch, fetchData]);

    const getMarketData = useCallback(async (id, setIsLoading) => {
        try {
            const response = await fetchData(`getmarket/${id}`, "GET", setIsLoading);
            dispatch(setSingleMarket(response?.data));
        } catch (error) {
            console.error("Error:", error);
        }
    }, [dispatch, fetchData]);

    const updateMarketData = useCallback(async (id, payload, setIsLoading) => {
        try {
            const response = await fetchData(`updatemarket/${id}`, "PUT", payload, setIsLoading);
            return response
        } catch (error) {
            console.error("Error:", error);
        }
    }, [fetchData]);
    //end market endpoints

    const fetchChartData = useCallback(async (payload, setIsLoading) => {
        try {
            const response = await fetchData("rates", "POST", payload, setIsLoading);
            dispatch(setChartData(response));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }, [dispatch, fetchData]);

    // start Market Details 
    const getCompetitorList = useCallback(async (id, setIsLoading) => {
        try {
            const response = await fetchData(`competitorslist/${id}`, "GET", setIsLoading);
            dispatch(setCompetitorList(response));
            return response
        } catch (error) {
            console.error("Error:", error);
        }
    }, [dispatch, fetchData]);
    // end Market Details

    // start category endpoints
    const fetchCustomCategoryDetils = useCallback(async (marketId, setIsLoading) => {
        setIsLoading(true);
        try {
            const response = await fetchData(`dashboard/categories/${marketId}`, "GET", setIsLoading);
            setIsLoading(false);
            dispatch(setCustomCategory(response?.data));
            dispatch(setCategoryIsLoading(false))
            return response?.data
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false);
        }
        //eslint-disable-next-line
    }, [fetchData]);

    const fetchCustomCategoryRates = useCallback(async (marketId, setIsLoading) => {
        setIsLoading(true);
        try {
            const response = await fetchData(`dashboard/categoriesrates/${marketId}`, "GET", setIsLoading);
            setIsLoading(false);
            return response?.data
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false);
        }
    }, [fetchData])

    const fetchRates = useCallback(async (payload, setIsLoading) => {
        setIsLoading(true);
        try {
            const response = await fetchData(`dashboard/categoriesrates`, "POST", payload, setIsLoading);
            setIsLoading(false);
            return response?.data
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false);
        }
    }, [fetchData])

    const fetchLastRefreshDate = useCallback(async (marketId, setIsLoading) => {
        setIsLoading(true);
        try {
            const response = await fetchData(`lastrefreshdate/${marketId}`, "GET", setIsLoading);
            dispatch(setLastRefreshDate(response?.lastRefreshDate || null));
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false);
        }
    }, [dispatch, fetchData]);

    const fetchCategory = useCallback(async (marketId, setIsLoading) => {
        setIsLoading(true)
        try {
            const response = await fetchData(`setup/compcategories/${marketId}`, "GET", setIsLoading);
            dispatch(setCategory(response?.categoriesData));
            setIsLoading(false)
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false)
        }
    }, [dispatch, fetchData]);

    // const fetchCustomCategory = useCallback(async (marketId, setIsLoading) => {
    //     setIsLoading(true)
    //     try {
    //         const response = await fetchData(`marketcategories/${marketId}`, "GET", setIsLoading);
               //dispatch(setCategoryIsLoading(false))

    //         // dispatch(setCustomCategory(response?.data));
    //         setIsLoading(false)
    //     } catch (error) {
    //         console.error("Error fetching data:", error);
    //         setIsLoading(false)
    //     }
    // }, [dispatch, fetchData])

    const addSetupCategory = useCallback(async (payload, setIsLoading) => {
        setIsLoading(true)
        try {
            const response = await fetchData("setup/categories", "PUT", payload, setIsLoading);
            return response
        } catch (error) {
            setIsLoading(false)
            console.error("Error fetching data:", error);
        }
    }, [fetchData]);

    const fetchAllCustomCategory = useCallback(async (marketId, setIsLoading) => {
        setIsLoading(true)
        try {
            const response = await fetchData(`setup/categories/${marketId}`, "GET", setIsLoading);
            const categoryIds = response?.data?.map((res) => res?.customCategoryId)?.sort((a, b) => a - b);
            dispatch(setAllCustomCategory({ allCustomCategory: response?.data, categoryIds: categoryIds }));
            setIsLoading(false);
            return categoryIds
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false)
        }
    }, [dispatch, fetchData])

    const deleteSetupCategory = useCallback(async (customCategoryId, setIsLoading) => {
        setIsLoading(true)
        try {
            const response = await fetchData(`setup/category/${customCategoryId}`, "DELETE", setIsLoading);
            setIsLoading(false);
            return response;
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false)
        }
    }, [fetchData]);

    const downloadCategoryReport = async (payload, setIsLoading) => {
        setIsLoading(true)
        const url = `${process.env.REACT_APP_API_URL}${`dashboard/categoryreport`}`;
        try {
            const response = await axios.post(url, payload, config);

            if (response?.message === "Data is not vaild !" || response?.status === 204) {
                setIsLoading(false)
                return "Data is not vaild !";
            } else {

                // const blob = new Blob([response.data], { type: 'application/pdf' });

                // const link = document.createElement('a');
                // link.href = window.URL.createObjectURL(blob);

                // const contentDisposition = response.headers['content-disposition'];
                // let filename = 'category-Report.pdf';
                // if (contentDisposition) {
                //     const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                //     const matches = filenameRegex.exec(contentDisposition);
                //     if (matches != null && matches[1]) {
                //         filename = matches[1].replace(/['"]/g, '');
                //     }
                // }

                // link.setAttribute('download', filename);

                // document.body.appendChild(link);

                // link.click();

                // document.body.removeChild(link);
                // setIsLoading(false)

                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);

                const contentDisposition = response.headers['content-disposition'];
                let filename = 'category-Report.xlsx'; // Default filename for XLSX files
                if (contentDisposition) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(contentDisposition);
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '');
                    }
                }

                link.setAttribute('download', filename);

                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                setIsLoading(false);
            }

        } catch (error) {
            console.error('Error downloading PDF:', error);
            setIsLoading(false);
            if (error?.response?.status === 400) {
                return "Data is not vaild !"
            }
        }
    };

    // end category endpoints

    /* Activity Report Start*/

    const fetchActivityReport = useCallback(async (marketId, setIsLoading) => {
        setIsLoading(true)
        try {
            const response = await fetchData(`activityreports/${marketId}`, "GET", setIsLoading);
            if (response?.message === "MarketId not found" || response === undefined) {
                dispatch(setActivityReport([]));
                dispatch(setActivityLOR([]));
            } else {
                if (response) {
                    dispatch(setActivityReport(response?.result?.length ? response?.result : []));
                    dispatch(setActivityLOR(response?.lor?.length ? response?.lor : []));
                } else {
                    dispatch(reSetActivityReport());
                }
            }
            setIsLoading(false)
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false)
        }
    }, [dispatch, fetchData]);

    const addActivityReport = useCallback(async (payload, setIsLoading) => {
        setIsLoading(true)
        try {
            const response = await fetchData("createactivityreport", "POST", payload, setIsLoading);
            return response
            // dispatch(setChartData(response));
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false)
        }
    }, [fetchData]);

    const updateActivityReport = useCallback(async (reportId, payload, setIsLoading) => {
        setIsLoading(true)
        try {
            const response = await fetchData(`updateactivityreport/${reportId}`, "PUT", payload, setIsLoading);
            return response
            // dispatch(setChartData(response));
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false)
        }
    }, [fetchData]);

    const deleteActivityReport = useCallback(async (marketId, setIsLoading) => {
        setIsLoading(true)
        try {
            const response = await fetchData(`activityreport/${marketId}`, "DELETE", setIsLoading);
            setIsLoading(false)
            return response;
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false)
        }
    }, [fetchData]);

    const downloadActivityReport = async (reportId, setIsLoading) => {
        setIsLoading(true)
        const url = `${process.env.REACT_APP_API_URL}${`downloadactivityreport/`}${reportId}`;
        try {
            const response = await axios.get(url, config);
            if (response?.message === "Data is not vaild !" || response?.status === 204) {
                setIsLoading(false)
                return "Data is not vaild !"
            } else {

                // const blob = new Blob([response.data], { type: 'application/pdf' });

                // const link = document.createElement('a');
                // link.href = window.URL.createObjectURL(blob);

                // const contentDisposition = response.headers['content-disposition'];
                // let filename = 'activity-report.pdf';
                // if (contentDisposition) {
                //     const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                //     const matches = filenameRegex.exec(contentDisposition);
                //     if (matches != null && matches[1]) {
                //         filename = matches[1].replace(/['"]/g, '');
                //     }
                // }

                // link.setAttribute('download', filename);

                // document.body.appendChild(link);

                // link.click();

                // document.body.removeChild(link);
                // setIsLoading(false)

                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);

                const contentDisposition = response.headers['content-disposition'];
                let filename = 'activity-report.xlsx'; // Default filename for XLSX files
                if (contentDisposition) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(contentDisposition);
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '');
                    }
                }

                link.setAttribute('download', filename);

                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                setIsLoading(false);


            }
        } catch (error) {
            console.error('Error downloading PDF:', error);
            setIsLoading(false)
            if (error?.response?.status === 400) {
                return "Data is not vaild !"
            }
        }
    };

    /* Activity Report End*/

    /* Market Alerts Start*/
    const getMarketAlerts = useCallback(async (payload, setIsLoading) => {
        setIsLoading(true)
        try {
            const response = await fetchData(`alerts`, "POST", payload, setIsLoading);
            dispatch(setAlertsData(response));
            setIsLoading(false)
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false)
        }
    }, [dispatch, fetchData]);

    const getSingleAlert = useCallback(async (alertId, setIsLoading) => {
        setIsLoading(true)
        try {
            const response = await fetchData(`getalert/${alertId}`, "GET", setIsLoading);
            setIsLoading(false)
            return response
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false)
        }
    }, [fetchData]);

    const getAllVendors = useCallback(async (marketcategoryid, setIsLoading) => {
        setIsLoading(true)
        try {
            const response = await fetchData(`categories/competitors/${marketcategoryid}`, "GET", setIsLoading);
            setIsLoading(false)
            return response
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false)
        }
    }, [fetchData]);

    const deleteMarketAlerts = useCallback(async (alertId, setIsLoading) => {
        setIsLoading(true)
        try {
            const response = await fetchData(`deletealert/${alertId}`, "DELETE", setIsLoading);
            setIsLoading(false)
            return response;
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false)
        }
    }, [fetchData]);

    const updateMarketAlert = useCallback(async (alertId, payload, setIsLoading) => {
        try {
            const response = await fetchData(`updatealert/${alertId}`, "PUT", payload);
            return response;
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }, [fetchData])

    const createMarketAlert = useCallback(async (payload, setIsLoading) => {
        try {
            const response = await fetchData("createalert", "POST", payload);
            return response
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }, [fetchData]);

    const getAllLogs = useCallback(async (marketId, currentPage, pagesize, setIsLoading) => {
        setIsLoading(true)
        try {
            const response = await fetchData(`alert/logs/${marketId}?size=${pagesize}&page=${currentPage}`, "GET", setIsLoading);
            dispatch(setLogsData(response));
            setIsLoading(false)
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false)
        }
    }, [dispatch, fetchData]);

    const getStatusLogs = useCallback(async (marketId, status, currentPage, pagesize, setIsLoading) => {
        setIsLoading(true)
        try {
            const response = await fetchData(`alert/logs/${marketId}?status=${status}&size=${pagesize}&page=${currentPage}`, "GET", setIsLoading);
            dispatch(setLogsData(response));
            setIsLoading(false)
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false)
        }
    }, [dispatch, fetchData]);

    const updateMarketLog = useCallback(async (logId, payload, currentPage, pagesize, status, setIsLoading) => {
        setIsLoading(true)
        try {
            if (status === null) {
                const response = await fetchData(`alertlog/${logId}?size=${pagesize}&page=${currentPage}`, "PUT", payload);
                dispatch(setLogsData(response));
                setIsLoading(false)
            } else {
                const response = await fetchData(`alertlog/${logId}?size=${pagesize}&page=${currentPage}&status=${status}`, "PUT", payload);
                dispatch(setLogsData(response));
                setIsLoading(false)
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false)
        }
    }, [dispatch, fetchData]);

    const downloadRatesData = async (payload, marketname, setIsLoading) => {
        setIsLoading(true)
        const url = `${process.env.REACT_APP_API_URL}${`ratesdownloaddata`}`;
        try {
            const response = await axios.post(url, payload, config);

            if (response?.message === "Data is not vaild !" || response?.status === 204) {
                setIsLoading(false)
                return "Data is not vaild !";
            } else {
                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);

                const contentDisposition = response.headers['content-disposition'];
                let filename = `${marketname}-${payload?.custom}-LOR${payload?.lor}.xlsx`; // Default filename for XLSX files
                if (contentDisposition) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(contentDisposition);
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '');
                    }
                }
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setIsLoading(false);
            }

        } catch (error) {
            console.error('Error downloading PDF:', error);
            setIsLoading(false);
            if (error?.response?.status === 400) {
                return "Data is not vaild !"
            }
        }
    };


    /* Market Alerts End*/
    return (
        {
            loginData,
            loginTokenUpdate,
            fetchMarketsData,
            getMarketData,
            updateMarketData,
            fetchChartData,
            fetchLastRefreshDate,
            fetchCategory,
            // fetchCustomCategory,
            fetchAllCustomCategory,
            addSetupCategory,
            deleteSetupCategory,
            downloadCategoryReport,
            fetchActivityReport,
            addActivityReport,
            updateActivityReport,
            deleteActivityReport,
            downloadActivityReport,
            getMarketAlerts,
            getAllVendors,
            deleteMarketAlerts,
            getSingleAlert,
            createMarketAlert,
            updateMarketAlert,
            getCompetitorList,
            getAllLogs,
            getStatusLogs,
            updateMarketLog,
            fetchCustomCategoryDetils,
            fetchCustomCategoryRates,
            fetchRates,
            downloadRatesData
        }
    )
}

export default ApiServices