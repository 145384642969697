import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useParams } from 'react-router-dom'
import { setBreadCrumbStack } from '../../services/slices/breadcrumbsSlice';
import { Breadcrumbs } from "@mui/material";

import HomeIcon from '@mui/icons-material/Home';

const BreadCrumbs = () => {
    const params = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const { breadCrumbStack } = useSelector(state => state?.breadCrumbs);
    const { data } = useSelector(state => (state?.allMarketsData?.data));
    const singleMarketData = data?.allMarkets?.filter((res) => parseInt(res?.marketId) === parseInt(params?.marketId));

    useEffect(() => {
        
        if (location?.pathname === "/markets") {
            const breadcrumData = { stackId: 2, path: "/markets", label: "All Markets" }
            const newBreadCrumbs = breadCrumbStack?.length > 1
                ? [...breadCrumbStack.slice(0, 1), breadcrumData]
                : [...breadCrumbStack, breadcrumData];
            if (JSON.stringify(newBreadCrumbs) !== JSON.stringify(breadCrumbStack)) {
                dispatch(setBreadCrumbStack(newBreadCrumbs));
            }
        } else if (location?.pathname === "/my-account") {
            const breadcrumData = { stackId: 2, path: "/my-account", label: "My Account" }
            const newBreadCrumbs = breadCrumbStack?.length > 1
                ? [...breadCrumbStack.slice(0, 1), breadcrumData]
                : [...breadCrumbStack, breadcrumData];
            if (JSON.stringify(newBreadCrumbs) !== JSON.stringify(breadCrumbStack)) {
                dispatch(setBreadCrumbStack(newBreadCrumbs));
            }
        }  else if (params?.categoryId || location?.state?.marketDetails) {
            const breadcrumData = {
                stackId: 4,
                path: `market-dashboard/${params?.marketId}/view-details/${params?.categoryId}`,
                state: location.state,
                label: location?.state?.category?.customCategoryLabel || location.state?.category?.customcategorylabel,
                customlabelid: parseInt(params?.categoryId),
            }
            const newBreadCrumbs = breadCrumbStack?.length > 3
                ? [...breadCrumbStack.slice(0, 3), breadcrumData]
                : [...breadCrumbStack, breadcrumData];
            if (JSON.stringify(newBreadCrumbs) !== JSON.stringify(breadCrumbStack)) {
                dispatch(setBreadCrumbStack(newBreadCrumbs));
            }
        } else if (
            params?.marketId ||
            (location?.state?.marketId &&
            location?.state?.marketName)
        ) {
           
            const breadcrumData = {
                stackId: 3,
                path: `/market-dashboard/${parseInt(params?.marketId)}`,
                state: location.state,
                label: singleMarketData?.[0]?.marketName,
                labelid: parseInt(params?.marketId)
            }
            const marketStack = { stackId: 2, path: "/markets", label: "All Markets" }
            const newBreadCrumbs = breadCrumbStack?.length > 2 ? breadCrumbStack?.map(data => data?.path)?.includes("/my-account") ?
                [...breadCrumbStack.slice(0, 1), marketStack, breadcrumData]
                : [...breadCrumbStack.slice(0, 2), breadcrumData]
                : [...breadCrumbStack, breadcrumData];
            if (JSON.stringify(newBreadCrumbs) !== JSON.stringify(breadCrumbStack)) {
                dispatch(setBreadCrumbStack(newBreadCrumbs));
            }
        }
    }, [breadCrumbStack, dispatch, location?.pathname,
        location.state, location.state?.category?.customCategoryLabel, location.state?.marketDetails,
        location.state?.marketId, location.state?.marketName, params?.categoryId,
        params?.marketId]);

    return (
        <div>
            <Breadcrumbs separator="/" aria-label="breadcrumb" className="header-breadcrums">
                {breadCrumbStack?.map((crumbs, id) => id === breadCrumbStack?.length - 1 ?
                    <span key={id}>{crumbs?.label}</span> :
                    crumbs?.label === "Home" ?
                        <Link key={id} to={crumbs?.path}>
                            <HomeIcon />
                        </Link> :
                        <Link key={id} to={crumbs?.path} state={crumbs?.state}>{crumbs?.label}</Link>
                )}
            </Breadcrumbs>
        </div>
    )
}

export default memo(BreadCrumbs)