import React, { Suspense, memo, useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import MainLayout from '../layouts/main-layout/MainLayout';
import DashboardLayout from '../layouts/dashboard-layout/DashboardLayout';
import LazyComponents from '../services/lazy-components/LazyComponents';

const MainRoutes = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { LazyLogin, LazyAllMarkets, LazyMyAccount, LazyCharts, LazyMarketDashboard, LazyNotFound, LazyMarketAlerts, LazyActivityReport, LazyCategorySetup  } = LazyComponents()
    const { accessToken } = useSelector(state => (state?.authDatas));

    useEffect(() => {
        if (!!accessToken && (location.pathname === "/")) {
            navigate("/markets")
        } else if (!accessToken && (location.pathname.includes("/markets") || location.pathname.includes("market-dashboard") || location.pathname.includes("my-account"))) {
            navigate("/")
        }
    }, [accessToken, location, navigate])

    return (
        <Routes>
            <Route path='/' element={<MainLayout />}>
                <Route index element={<LazyLogin />} />
            </Route>
            <Route path='/markets' element={<DashboardLayout />}>
                <Route index element={<LazyAllMarkets />} />
            </Route>

            <Route path='/market-dashboard/:marketId' element={<DashboardLayout />}>
                <Route index element={<LazyMarketDashboard />} />
                <Route path="view-details/:categoryId" element={<LazyCharts />} />
                <Route path="category-setup" element={<LazyCategorySetup />} />
                <Route path="market-alerts" element={<LazyMarketAlerts />} />
                <Route path="activity-report" element={<LazyActivityReport />} />
            </Route>

            <Route path='/my-account' element={<DashboardLayout />}>
                <Route index element={<LazyMyAccount />} />
            </Route>
            {!accessToken ? <Route path='/*' element={<Suspense fallback={"loading..."}>
                <LazyNotFound />
            </Suspense>} /> :
                <Route path='/*' element={<Navigate to={"/markets"} />} />
            }
        </Routes>
    )
}

export default memo(MainRoutes)