import { createSlice } from "@reduxjs/toolkit";

const initialState = { customCategory: [], customCategoryDetails: [] , categoryIsLoading: false};

const customCategorySlice = createSlice({
    name: "categories",
    initialState,
    reducers: {
        resetCustomCategory(state) {
            state.customCategory = []
            state.customCategoryDetails = []
        },
        setCustomCategory: (state, action) => {
            state.customCategory = action.payload
        },
        setCategoryIsLoading: (state, action) => {
            state.categoryIsLoading = action.payload
        }
    }
});

export const { resetCustomCategory, setCustomCategory, setCategoryIsLoading } = customCategorySlice?.actions;

export default customCategorySlice?.reducer