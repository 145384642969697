import { createSlice } from "@reduxjs/toolkit";

const logDatas = [];

const alertLogs = createSlice({
    name: "alertLogs",
    initialState: { logDatas },
    reducers: {
        reSetLogsData(state) {
            state.logDatas = logDatas
        },
        setLogsData(state, action) {
            state.logDatas = action.payload
        },
    }
})

export const { setLogsData, reSetLogsData } = alertLogs?.actions;

export default alertLogs?.reducer;