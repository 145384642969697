import { createSlice } from "@reduxjs/toolkit";

const alertDatas = [];

const alertSlice = createSlice({
    name: "alertSlice",
    initialState: { alertDatas },
    reducers: {
        reSetAlertsData(state) {
            state.alertDatas = alertDatas
        },
        setAlertsData(state, action) {
            state.alertDatas = action.payload
        },
    }
})

export const { setAlertsData, reSetAlertsData } = alertSlice?.actions;

export default alertSlice?.reducer;