import { createSlice } from "@reduxjs/toolkit";

const initialState = { category: [] }

const categorySlice = createSlice({
    name: "categories",
    initialState,
    reducers: {
        resetCategory(state) {
            state.category = []
        },
        setCategory: (state, action) => {
            state.category = action.payload
        }
    }
});

export const { resetCategory, setCategory } = categorySlice?.actions;

export default categorySlice?.reducer