import { createSlice } from "@reduxjs/toolkit";

const isSidemenuOpen = true;

const isSidemenuSlice = createSlice({
  name: "issidemenuopen",
  initialState: {
    isSidemenuOpen,
  },
  reducers: {
    setIsSidemenuOpen(state, action) {
      state.isSidemenuOpen = action.payload;
    },
  },
});

export const { setIsSidemenuOpen } = isSidemenuSlice.actions;

export default isSidemenuSlice.reducer;
