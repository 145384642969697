
const enterFullscreen = (tableRef) => {
    const elem = tableRef.current;
    if (elem?.requestFullscreen) {
      elem?.requestFullscreen();
    } else if (elem?.mozRequestFullScreen) {
      elem?.mozRequestFullScreen();
    } else if (elem?.webkitRequestFullscreen) {
      elem?.webkitRequestFullscreen();
    }
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  };

  export const fullPageView = (tableRef) => {
    if (!document.fullscreenElement) {
      enterFullscreen(tableRef);
    } else {
      exitFullscreen();
    }
  };

  export const lorArrayfinal = (data) => {
    if(data === null) {
      return [1]
    } else if (data === undefined) {
      return [1]
    } else {
      return data
    }
  }
