import { createSlice } from "@reduxjs/toolkit";

const isDaysRange = 30;

const isDaysRangeSlice = createSlice({
    name: "issidemenuopen",
    initialState: {
        isDaysRange,
    },
    reducers: {
        resetIsDaysRange(state) {
            state.isDaysRange = isDaysRange
        },
        setIsDaysRange(state, action) {
            state.isDaysRange = action.payload;
        },
    },
});

export const { setIsDaysRange, resetIsDaysRange } = isDaysRangeSlice.actions;

export default isDaysRangeSlice.reducer;