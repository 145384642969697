import { createSlice } from "@reduxjs/toolkit";

const isLastRefreshDate = "";

const isLastRefreshDateSlice = createSlice({
    name: "issidemenuopen",
    initialState: {
        isLastRefreshDate,
    },
    reducers: {
        reLastRefreshDate(state) {
            state.isLastRefreshDate = isLastRefreshDate
        },
        setLastRefreshDate(state, action) {
            state.isLastRefreshDate = action.payload;
        },
    },
});

export const { reLastRefreshDate, setLastRefreshDate } = isLastRefreshDateSlice.actions;

export default isLastRefreshDateSlice.reducer;