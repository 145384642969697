import { createSlice } from "@reduxjs/toolkit";

const data = {};

const marketsDataSlice = createSlice({
    name: "marketsData",
    initialState: {
        data
    },
    reducers: {
        resetSingleMarket(state) {
            state.data = {}
        },
        setSingleMarket(state, action) {
            state.data = action?.payload
        }
    }
});

export const { resetSingleMarket, setSingleMarket } = marketsDataSlice?.actions;

export default marketsDataSlice?.reducer;
