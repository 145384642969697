import { createSlice } from "@reduxjs/toolkit";

const excelData = [];

const chartDataSlice = createSlice({
    name: "chartData",
    initialState: {
        filteredData: excelData
    },
    reducers: {
        resetChartData(state) {
            state.filteredData = excelData
        },
        setChartData(state, action) {
            state.filteredData = action.payload
        }
    }
})

export const { resetChartData, setChartData } = chartDataSlice?.actions;

export default chartDataSlice?.reducer