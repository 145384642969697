import { createSlice } from "@reduxjs/toolkit";

const isExpand = {expandSideBar:false,expandNavBar:false};

const isExpandSlice = createSlice({
  name: "isexpand",
  initialState: {
    isExpand,
  },
  reducers: {
    reSetIsExpand(state){
      state.isExpand = isExpand
    },
    setIsExpand(state, action) {
      state.isExpand = action.payload;
    },
  },
});

export const { setIsExpand,reSetIsExpand } = isExpandSlice.actions;

export default isExpandSlice.reducer;
