import { createSlice } from "@reduxjs/toolkit";

const initialState = { allCustomCategory: [] , categoryIds : []};

const allCustomCategorySlice = createSlice({
    name: "allcategories",
    initialState,
    reducers: {
        resetAllCustomCategory(state) {
            state.allCustomCategory = [];
            state.categoryIds =[];
        },
        setAllCustomCategory: (state, action) => {
            state.allCustomCategory = action.payload.allCustomCategory;
            state.categoryIds = action.payload.categoryIds
        }
    }
});

export const { resetAllCustomCategory, setAllCustomCategory } = allCustomCategorySlice?.actions;

export default allCustomCategorySlice?.reducer