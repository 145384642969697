import { reSetActivityReport } from "../../services/slices/activityReport";
import { resetChartData } from "../../services/slices/allChartData";
import { resetMarketsData } from "../../services/slices/allMarketsData";
import { reSetAuthData } from "../../services/slices/authSlice";
import { reSetBreadCrumbStack } from "../../services/slices/breadcrumbsSlice";
import { resetCategory } from "../../services/slices/categorySlice";
import { resetFilter } from "../../services/slices/chartFilter";
import { resetCustomCategory } from "../../services/slices/customCategorySlice";
import { resetIsDaysRange } from "../../services/slices/daysRangeSlice";
import { reSetIsExpand } from "../../services/slices/expandSlice";
import { reSetIsLOR } from "../../services/slices/lorSlice";
import { resetSingleMarket } from "../../services/slices/marketdata";
import { persistor } from "../../store/store";
import { reSetAlertsData } from "../../services/slices/alertSlice";
import { resetAllCustomCategory } from "../../services/slices/allCustomCategory";
import { resetCompetitorList } from "../../services/slices/competitorList";
import { reLastRefreshDate } from "../../services/slices/lastRefreshDate";
import { reSetLogsData } from "../../services/slices/alertLogs";

export const clearReduxData = (dispatch) => {
    dispatch(reSetAuthData());
    dispatch(resetMarketsData());
    dispatch(resetFilter());
    dispatch(resetChartData());
    dispatch(reSetAuthData());
    dispatch(reSetBreadCrumbStack());
    dispatch(reSetIsExpand());
    dispatch(resetSingleMarket());
    dispatch(reSetIsLOR());
    dispatch(resetIsDaysRange());
    dispatch(resetCategory());
    dispatch(resetCustomCategory());
    dispatch(reSetActivityReport());
    dispatch(reSetAlertsData());
    dispatch(resetAllCustomCategory());
    dispatch(resetCompetitorList());
    dispatch(reLastRefreshDate());
    dispatch(reSetLogsData());
}

export const logout = async (navigate, dispatch) => {
    clearReduxData(dispatch)
    try {
        await persistor.purge()
            .then(() => {
                navigate("/")
            });
    } catch (error) {
        console.error('Error clearing persisted state:', error);
    }
}
