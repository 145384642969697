import { createSlice } from "@reduxjs/toolkit";

const activityReportData = [];
const lorOptions = [];

const addNewActiviryReport = createSlice({
    name: "addnewactivityreport",
    initialState: {
        activityReportData,
        lorOptions,
    },
    reducers: {
        reSetActivityReport(state) {
            state.activityReportData = [];
            state.lorOptions = []
        },
        setActivityReport(state, action) {
            state.activityReportData = action?.payload
        },
        setActivityLOR(state, action) {
            state.lorOptions = action?.payload
        }
    }
});

export const { setActivityReport, reSetActivityReport, setActivityLOR } = addNewActiviryReport?.actions;

export default addNewActiviryReport?.reducer;
